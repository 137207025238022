<template>
  <router-view />
</template>

<script>
import "@/assets/js/promotion/t-map/common";

export default {
  name: "TMapLayout",
};
</script>

<style scoped>
@import "~@/assets/fonts/cafe24Ohsquare.css";
@import "~@/assets/fonts/pretendard.css";
@import "~@/assets/css/promotion/t-map/normalize.css";
@import "~@/assets/css/promotion/t-map/common.css";
@import "~@/assets/css/promotion/t-map/content.css";
</style>
